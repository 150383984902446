"use client";
import { SearchCategory } from "@/api/search/types";
import { PropsWithChildren } from "react";
import { MenuItem, Navigation } from "../../Layout/Navigation";
import SearchMenuItem from "./SearchMenuItem";
import { DropdownInput } from "../../Basic/DropdownInput";
import FilterIcon from "@/assets/icons/filter.svg";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { twMerge } from "tailwind-merge";
import { marketplaceOptions } from "@/shared/constants";

const menuItems: MenuItem[] = [
  {
    name: <SearchMenuItem id={SearchCategory.Collections} name="Collections" />,
    href: `/search/collections`,
  },
  {
    name: <SearchMenuItem id={SearchCategory.Artists} name="Artists" />,
    href: `/search/artists`,
  },
  {
    name: <SearchMenuItem id={SearchCategory.Artwork} name="Artwork" />,
    href: `/search/artwork`,
  },
];

function SearchPageWrapper({ children }: PropsWithChildren) {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const hideMarketplaceFilter = [
    "/search/artists",
    "/search/collections",
  ].includes(pathname);

  const marketplace = searchParams.get("marketplace");

  const onMarketplaceChange = (option: string) => {
    const params = new URLSearchParams(searchParams.toString());
    if (option) {
      params.set("marketplace", option);
    } else {
      params.delete("marketplace");
    }
    router.push(pathname + "?" + params.toString());
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <Navigation
          menuItems={menuItems}
          shouldHideActiveIndicatorOnScroll={false}
          minimizeOnScroll={true}
          className="lg:-bottom-2"
          preserveSearchQuery
        />
      </div>
      <div
        className={twMerge(
          "w-full flex justify-end mb-10 pr-4 lg:pr-0",
          hideMarketplaceFilter && "hidden",
        )}
      >
        <DropdownInput
          text="Filter by:"
          startIcon={<FilterIcon />}
          selectedOption={marketplace || undefined}
          options={marketplaceOptions}
          setSelectedOption={onMarketplaceChange}
        />
      </div>
      {children}
    </>
  );
}

export default SearchPageWrapper;
