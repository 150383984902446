"use client";
import React, {
  useEffect,
  useRef,
  useState,
  useTransition,
  useMemo,
} from "react";
import SearchIcon from "@/assets/icons/search.svg";
import ArrowLeftIcon from "@/assets/icons/arrow-left-small.svg";
import { twMerge } from "tailwind-merge";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useKeyPress } from "@/shared/hooks/useKeyPress";
import useOutsideClick from "@/shared/hooks/useOutsideClick";
import { SubmitType } from "./types";

export interface Props {
  onSubmit?: () => void;
  initialValue?: string;
  submitType?: SubmitType;
  expandedByDefault?: boolean;
  className?: string;
  inputClassName?: string;
  small?: boolean;
}

const SearchInput = ({
  onSubmit = () => {},
  expandedByDefault,
  submitType = SubmitType.Submit,
  className,
  inputClassName,
  small,
}: Props) => {
  const searchParams = useSearchParams();
  const searchTerm = searchParams.get("searchTerm");
  const router = useRouter();
  const pathname = usePathname();
  const [expanded, setExpanded] = useState(expandedByDefault);
  const [, startTransition] = useTransition();
  const ref = useRef<HTMLInputElement>(null);

  const searchRef = useOutsideClick<HTMLDivElement>({
    handler: () => setExpanded((prevState) => !prevState),
    enabled: !!expanded,
  });

  useKeyPress({
    key: "Escape",
    onKeyPress: () => setExpanded((prevState) => !prevState),
    enable: !!expanded,
  });

  const [search, setSearch] = useState(searchTerm);

  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm, pathname]);

  const handleSubmit = () => {
    onSubmit();
    setExpanded(!expanded);
    if (submitType === SubmitType.Submit && !pathname.includes("search")) {
      router.push(`/search?searchTerm=${search}`);
    } else {
      router.push(`${pathname}?searchTerm=${search}`);
    }
    setSearch("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    if (submitType === SubmitType.Submit) return;
    startTransition(() => {
      router.replace(`${pathname}?searchTerm=${value}`);
    });
  };

  const handleClick = () => {
    if (!search) {
      setExpanded(!expanded);
      ref.current!.focus();
    } else {
      handleSubmit();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && search) {
      handleSubmit();
    }
  };

  const isExpanded = useMemo(
    () => expandedByDefault || expanded,
    [expanded, expandedByDefault],
  );

  return (
    <div
      ref={searchRef}
      className={twMerge(
        "transition-all duration-400 ease-in-out box-border  border-2 border-primary border-solid rounded-full h-11 py-[2px] px-1 absolute right-0 flex items-center light:bg-primary night:bg-primary",
        small && "box-content p-0 h-8",
        isExpanded && "endemic:bg-primary endemic:border-invert",
        isExpanded &&
          small &&
          "border-x-0 border-t-0 !right-0 w-full z-50 border-b-3 h-full rounded-none",
        className,
      )}
    >
      <input
        type="text"
        ref={ref}
        value={search || ""}
        className={twMerge(
          "border-none outline-none text-base transition-all duration-200 ease-in-out bg-transparent text-primary endemic:text-invert ml-2",
          isExpanded
            ? small
              ? "ml-10 min-w-[calc(100%-100px)]"
              : "px-2 w-[208px] lg:w-[280px]"
            : "w-0 ml-0",
          inputClassName,
        )}
        onChange={(e) => {
          handleChange(e);
        }}
        onKeyDown={handleKeyDown}
        placeholder="Search"
      />
      <ArrowLeftIcon
        className={twMerge(
          isExpanded && small
            ? "block w-[10px] absolute left-5 fill-secondary endemic:fill-endemicGray-700"
            : "hidden",
        )}
      />
      <div
        className={twMerge(
          "flex justify-center items-center w-8 h-8 cursor-pointer",
          isExpanded && small && "mr-3",
        )}
        onClick={handleClick}
        role="button"
      >
        <SearchIcon
          className={twMerge(
            "fill-primary",
            isExpanded ? "lg:endemic:fill-invert" : "",
            small ? "w-4 h-4" : "w-5 h-5",
          )}
        />
      </div>
    </div>
  );
};

export default SearchInput;
