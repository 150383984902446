"use client";
import { Fragment, useMemo } from "react";
import { Listbox } from "@headlessui/react";
import ArrowDownSmallIcon from "@/assets/icons/arrow-down-small.svg";
import { twMerge } from "tailwind-merge";
import { Overlay } from "../Overlay";

type OptionValue = string | number;

export interface Option<T extends OptionValue> {
  value: T;
  label: string | React.JSX.Element;
}

export interface Props<T extends OptionValue> {
  text?: string;
  options?: Option<T>[];
  startIcon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  childrenClassName?: string;
  borderClassName?: string;
  arrowClassName?: string;
  setSelectedOption?: (option: T) => void;
  selectedOption?: T;
  displayOverlay?: boolean;
}

function DropdownInput<T extends OptionValue>({
  text,
  options,
  startIcon,
  className,
  disabled,
  childrenClassName,
  borderClassName,
  arrowClassName,
  selectedOption,
  setSelectedOption,
  displayOverlay = false,
}: Props<T>) {
  const label = useMemo(() => {
    const option = options?.find((option) => option.value === selectedOption);
    return option?.label;
  }, [options, selectedOption]);

  const disableScroll = (isDisabled: boolean) => {
    if (typeof document !== "undefined" && displayOverlay) {
      if (isDisabled) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
  };
  return (
    <>
      <Listbox
        value={selectedOption}
        onChange={setSelectedOption}
        as="div"
        className={`relative ${displayOverlay && "z-20"}`}
        disabled={disabled}
      >
        {({ open }) => {
          disableScroll(open);

          return (
            <>
              <Listbox.Button
                className={twMerge(
                  `flex items-center justify-between border-2 rounded-[30px] text-primary endemic:text-invert bg-primary h-[60px] w-[300px] px-5 py-6 outline-none ${
                    open
                      ? twMerge(
                          "border-primary endemic:border-primary relative !opacity-100 z-20",
                          borderClassName,
                        )
                      : "border-endemicGray-300 night:border-endemicGray-900"
                  }`,
                  className,
                )}
              >
                <span className="flex items-center">
                  {startIcon && (
                    <span className="mr-3 fill-primary endemic:fill-invert w-[18px] h-[18px] flex items-center justify-center">
                      {startIcon}
                    </span>
                  )}
                  <span className="font-light">
                    {text}
                    <span
                      className={twMerge(
                        `${label ? "font-semibold" : "text-secondary"} ml-1`,
                        childrenClassName,
                        "cursor-pointer",
                      )}
                    >
                      {label ? (
                        label
                      ) : (
                        <span className="text-endemicGray-700 endemic:text-secondary whitespace-nowrap">
                          Select option
                        </span>
                      )}
                    </span>
                  </span>
                </span>
                <ArrowDownSmallIcon
                  className={twMerge(
                    "fill-primary endemic:fill-primary w-[10px] h-[6px] mt-0.5",
                    arrowClassName,
                  )}
                />
              </Listbox.Button>

              {
                <Listbox.Options
                  className={twMerge(
                    `absolute z-20 border-primary endemic:border-invert border-2 w-full top-[120%] p-4 rounded-[20px] text-primary endemic:text-invert bg-primary ${
                      displayOverlay
                        ? "max-h-[250px] overflow-auto custom-scroll"
                        : ""
                    }`,
                    childrenClassName,
                  )}
                >
                  {options?.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      value={option.value}
                      as={Fragment}
                    >
                      {({ active, selected }) => (
                        <div
                          className={twMerge(
                            "px-[15px] py-[10px] flex gap-x-3 items-center rounded-[10px]",
                            `${
                              active
                                ? "bg-endemicGray-100 night:bg-[#2A2A2A] cursor-pointer"
                                : "bg-transparent"
                            }`,
                          )}
                        >
                          <div
                            className={twMerge(
                              "w-5 h-5 rounded-[50%]",
                              `${
                                selected
                                  ? "border-primary endemic:border-invert border-[6px]"
                                  : "border-endemicGray-300 night:border-endemicGray-900 text-gray border-[2px]"
                              }`,
                            )}
                          ></div>
                          <li>{option?.label}</li>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              }
              {displayOverlay && <Overlay isVisible={open} />}
            </>
          );
        }}
      </Listbox>
    </>
  );
}

export default DropdownInput;
