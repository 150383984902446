import { useWeb3State, useWeb3Actions } from "@/web3/context";
import { Modal } from "../../Basic/Modal";
import WalletConnectButton from "../../Basic/WalletConnectButton/WalletConnectButton";
import { FormSectionContainer } from "../Settings/FormSectionContainer";
import MetamaskIcon from "@/assets/icons/metamask.svg";
import WalletconnectIcon from "@/assets/icons/walletConnect.svg";
import CoinbaseIcon from "@/assets/icons/coinbase.svg";
import useMe from "@/shared/hooks/useMe";
import { ConnectorType } from "@/shared/constants";
import { useState } from "react";

export default function ConnectWalletPopup() {
  const { isWalletPickerOpen } = useWeb3State();
  const { toggleWalletPicker, disconnect } = useWeb3Actions();
  const [err, setErr] = useState<string>("");

  const { me } = useMe();
  const onClose = () => {
    toggleWalletPicker();
  };

  const handleError = (errMessage: string) => {
    setErr(errMessage);
    disconnect();
  };

  if (!me) return null;

  return (
    <Modal
      hasCloseButton
      isOpen={isWalletPickerOpen}
      onClose={onClose}
      className="max-w-[1340px]"
    >
      <FormSectionContainer
        title="Wallet"
        text="If you don’t have a wallet yet, you can select a provider and create one now. Connecting a wallet is necessary to participate in auctions, bid for artwork and collections and/or receive payments."
        className="items-center lg:items-center border-none"
      >
        <div className="flex justify-between items-center mb-6 lg:mb-4">
          <div className="flex md:gap-x-4 lg:gap-x-6 gap-x-1 items-center">
            <MetamaskIcon className="w-8 lg:w-10" />
            <label>Metamask</label>
          </div>
          <WalletConnectButton
            userId={me!.id}
            connector={ConnectorType.Metamask}
            onError={handleError}
            onConnect={toggleWalletPicker}
          />
        </div>

        <div className="flex justify-between items-center mb-6 lg:mb-4">
          <div className="flex md:gap-x-4 lg:gap-x-6 gap-x-1 items-center">
            <WalletconnectIcon className="w-8 lg:w-10" />
            <label>Wallet Connect</label>
          </div>
          <WalletConnectButton
            userId={me!.id}
            connector={ConnectorType.WalletConnect}
            onError={handleError}
            onConnect={toggleWalletPicker}
            isWalletPicker={isWalletPickerOpen}
          />
        </div>
        <div className="flex justify-between items-center mb-6 lg:mb-4">
          <div className="flex md:gap-x-4 lg:gap-x-6 gap-x-1 items-center">
            <CoinbaseIcon className="w-8 lg:w-10" />
            <label>Coinbase</label>
          </div>
          <WalletConnectButton
            userId={me!.id}
            connector={ConnectorType.Coinbase}
            onError={handleError}
            onConnect={toggleWalletPicker}
            isWalletPicker={isWalletPickerOpen}
          />
        </div>
        <span className={"text-red"}>{err}</span>
      </FormSectionContainer>
    </Modal>
  );
}
